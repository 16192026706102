const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['getUser'] = require('../middleware/getUser.js')
middleware['getUser'] = middleware['getUser'].default || middleware['getUser']

middleware['global-redirects'] = require('../middleware/global-redirects.js')
middleware['global-redirects'] = middleware['global-redirects'].default || middleware['global-redirects']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['handleBackUrl'] = require('../middleware/handleBackUrl.js')
middleware['handleBackUrl'] = middleware['handleBackUrl'].default || middleware['handleBackUrl']

middleware['handleExternalToken'] = require('../middleware/handleExternalToken.js')
middleware['handleExternalToken'] = middleware['handleExternalToken'].default || middleware['handleExternalToken']

middleware['handleMissingRoomSlug'] = require('../middleware/handleMissingRoomSlug.js')
middleware['handleMissingRoomSlug'] = middleware['handleMissingRoomSlug'].default || middleware['handleMissingRoomSlug']

middleware['handleMissingSignupData'] = require('../middleware/handleMissingSignupData.js')
middleware['handleMissingSignupData'] = middleware['handleMissingSignupData'].default || middleware['handleMissingSignupData']

middleware['hydrate'] = require('../middleware/hydrate.js')
middleware['hydrate'] = middleware['hydrate'].default || middleware['hydrate']

middleware['liveAdmin'] = require('../middleware/liveAdmin.js')
middleware['liveAdmin'] = middleware['liveAdmin'].default || middleware['liveAdmin']

middleware['liveRedirect'] = require('../middleware/liveRedirect.js')
middleware['liveRedirect'] = middleware['liveRedirect'].default || middleware['liveRedirect']

middleware['logout'] = require('../middleware/logout.js')
middleware['logout'] = middleware['logout'].default || middleware['logout']

middleware['mixpanel'] = require('../middleware/mixpanel.js')
middleware['mixpanel'] = middleware['mixpanel'].default || middleware['mixpanel']

middleware['mobileOnlyRoute'] = require('../middleware/mobileOnlyRoute.js')
middleware['mobileOnlyRoute'] = middleware['mobileOnlyRoute'].default || middleware['mobileOnlyRoute']

middleware['modals'] = require('../middleware/modals.js')
middleware['modals'] = middleware['modals'].default || middleware['modals']

middleware['onlyInterRealm'] = require('../middleware/onlyInterRealm.js')
middleware['onlyInterRealm'] = middleware['onlyInterRealm'].default || middleware['onlyInterRealm']

middleware['partner'] = require('../middleware/partner.js')
middleware['partner'] = middleware['partner'].default || middleware['partner']

middleware['phoneOrEmailAb'] = require('../middleware/phoneOrEmailAb.js')
middleware['phoneOrEmailAb'] = middleware['phoneOrEmailAb'].default || middleware['phoneOrEmailAb']

middleware['realms'] = require('../middleware/realms.js')
middleware['realms'] = middleware['realms'].default || middleware['realms']

middleware['redirectAuthenticatedUser'] = require('../middleware/redirectAuthenticatedUser.js')
middleware['redirectAuthenticatedUser'] = middleware['redirectAuthenticatedUser'].default || middleware['redirectAuthenticatedUser']

middleware['redirectAuthUser'] = require('../middleware/redirectAuthUser.js')
middleware['redirectAuthUser'] = middleware['redirectAuthUser'].default || middleware['redirectAuthUser']

middleware['redirectPdtUser'] = require('../middleware/redirectPdtUser.js')
middleware['redirectPdtUser'] = middleware['redirectPdtUser'].default || middleware['redirectPdtUser']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['setGadsFromExperience'] = require('../middleware/setGadsFromExperience.js')
middleware['setGadsFromExperience'] = middleware['setGadsFromExperience'].default || middleware['setGadsFromExperience']

middleware['setHomeVersion'] = require('../middleware/setHomeVersion.js')
middleware['setHomeVersion'] = middleware['setHomeVersion'].default || middleware['setHomeVersion']

middleware['setInterOnboardingExperience'] = require('../middleware/setInterOnboardingExperience.js')
middleware['setInterOnboardingExperience'] = middleware['setInterOnboardingExperience'].default || middleware['setInterOnboardingExperience']

middleware['setOnboardingExperience'] = require('../middleware/setOnboardingExperience.js')
middleware['setOnboardingExperience'] = middleware['setOnboardingExperience'].default || middleware['setOnboardingExperience']

middleware['setPartnerExperience'] = require('../middleware/setPartnerExperience.js')
middleware['setPartnerExperience'] = middleware['setPartnerExperience'].default || middleware['setPartnerExperience']

middleware['su'] = require('../middleware/su.js')
middleware['su'] = middleware['su'].default || middleware['su']

export default middleware
