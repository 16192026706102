import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faGoogle as freeFabFaGoogle,
  faFacebookF as freeFabFaFacebookF,
  faWhatsapp as freeFabFaWhatsapp,
  faBitcoin as freeFabFaBitcoin,
  faYoutube as freeFabFaYoutube,
  faInstagram as freeFabFaInstagram,
  faApple as freeFabFaApple,
  faGooglePlay as freeFabFaGooglePlay
} from '@fortawesome/free-brands-svg-icons'

import {
  faAngleDown as proFalFaAngleDown,
  faAngleUp as proFalFaAngleUp,
  faAnalytics as proFalFaAnalytics,
  faArrowAltDown as proFalFaArrowAltDown,
  faArrowAltUp as proFalFaArrowAltUp,
  faLongArrowLeft as proFalFaLongArrowLeft,
  faArrowToRight as proFalFaArrowToRight,
  faArrowToBottom as proFalFaArrowToBottom,
  faArrowToTop as proFalFaArrowToTop,
  faBalanceScaleLeft as proFalFaBalanceScaleLeft,
  faBadgePercent as proFalFaBadgePercent,
  faBallotCheck as proFalFaBallotCheck,
  faBell as proFalFaBell,
  faBellExclamation as proFalFaBellExclamation,
  faBellOn as proFalFaBellOn,
  faBellPlus as proFalFaBellPlus,
  faBellSlash as proFalFaBellSlash,
  faCalendar as proFalFaCalendar,
  faCalendarAlt as proFalFaCalendarAlt,
  faCamera as proFalFaCamera,
  faChartBar as proFalFaChartBar,
  faChartLine as proFalFaChartLine,
  faChartLineDown as proFalFaChartLineDown,
  faChartPie as proFalFaChartPie,
  faChartNetwork as proFalFaChartNetwork,
  faChartPieAlt as proFalFaChartPieAlt,
  faChartScatter as proFalFaChartScatter,
  faCheckCircle as proFalFaCheckCircle,
  faCircle as proFalFaCircle,
  faClock as proFalFaClock,
  faClone as proFalFaClone,
  faCog as proFalFaCog,
  faCommentAltSlash as proFalFaCommentAltSlash,
  faCommentAltDollar as proFalFaCommentAltDollar,
  faCommentCheck as proFalFaCommentCheck,
  faCommentPlus as proFalFaCommentPlus,
  faCreditCardFront as proFalFaCreditCardFront,
  faCreditCard as proFalFaCreditCard,
  faDesktop as proFalFaDesktop,
  faDiploma as proFalFaDiploma,
  faDonate as proFalFaDonate,
  faEnvelope as proFalFaEnvelope,
  faEnvelopeOpenDollar as proFalFaEnvelopeOpenDollar,
  faExchange as proFalFaExchange,
  faExclamationCircle as proFalFaExclamationCircle,
  faExclamationTriangle as proFalFaExclamationTriangle,
  faFile as proFalFaFile,
  faFileAlt as proFalFaFileAlt,
  faFileChartPie as proFalFaFileChartPie,
  faFileDownload as proFalFaFileDownload,
  faFileImage as proFalFaFileImage,
  faFileInvoiceDollar as proFalFaFileInvoiceDollar,
  faFilePdf as proFalFaFilePdf,
  faFilter as proFalFaFilter,
  faFlag as proFalFaFlag,
  faFrown as proFalFaFrown,
  faGlobe as proFalFaGlobe,
  faHistory as proFalFaHistory,
  faHourglassStart as proFalFaHourglassStart,
  faHourglassEnd as proFalFaHourglassEnd,
  faLaptopCode as proFalFaLaptopCode,
  faLayerMinus as proFalFaLayerMinus,
  faLayerPlus as proFalFaLayerPlus,
  faLevelDown as proFalFaLevelDown,
  faLevelUp as proFalFaLevelUp,
  faLightbulbSlash as proFalFaLightbulbSlash,
  faLightbulbOn as proFalFaLightbulbOn,
  faLightbulbDollar as proFalFaLightbulbDollar,
  faLockAlt as proFalFaLockAlt,
  faHandHoldingUsd as proFalFaHandHoldingUsd,
  faHashtag as proFalFaHashtag,
  faHeart as proFalFaHeart,
  faIdBadge as proFalFaIdBadge,
  faLaugh as proFalFaLaugh,
  faMailBulk as proFalFaMailBulk,
  faMapMarkerCheck as proFalFaMapMarkerCheck,
  faMapMarkerSlash as proFalFaMapMarkerSlash,
  faMapMarkerAlt as proFalFaMapMarkerAlt,
  faMeh as proFalFaMeh,
  faMobileAndroid as proFalFaMobileAndroid,
  faNewspaper as proFalFaNewspaper,
  faPaperclip as proFalFaPaperclip,
  faPaperPlane as proFalFaPaperPlane,
  faPlay as proFalFaPlay,
  faPlayCircle as proFalFaPlayCircle,
  faPlusCircle as proFalFaPlusCircle,
  faQrcode as proFalFaQrcode,
  faQuestionCircle as proFalFaQuestionCircle,
  faRadar as proFalFaRadar,
  faRandom as proFalFaRandom,
  faSearch as proFalFaSearch,
  faSearchDollar as proFalFaSearchDollar,
  faShareAll as proFalFaShareAll,
  faScroll as proFalFaScroll,
  faSignalStream as proFalFaSignalStream,
  faSignIn as proFalFaSignIn,
  faSignOut as proFalFaSignOut,
  faStream as proFalFaStream,
  faStar as proFalFaStar,
  faTag as proFalFaTag,
  faTachometerFast as proFalFaTachometerFast,
  faTachometerAltFastest as proFalFaTachometerAltFastest,
  faTachometerAltSlow as proFalFaTachometerAltSlow,
  faThumbsUp as proFalFaThumbsUp,
  faThumbsDown as proFalFaThumbsDown,
  faThumbtack as proFalFaThumbtack,
  faTimes as proFalFaTimes,
  faTicket as proFalFaTicket,
  faTimesCircle as proFalFaTimesCircle,
  faToggleOff as proFalFaToggleOff,
  faToggleOn as proFalFaToggleOn,
  faUniversity as proFalFaUniversity,
  faUnlockAlt as proFalFaUnlockAlt,
  faUser as proFalFaUser,
  faUserChart as proFalFaUserChart,
  faUserCircle as proFalFaUserCircle,
  faUserCog as proFalFaUserCog,
  faUserCrown as proFalFaUserCrown,
  faUsersCrown as proFalFaUsersCrown,
  faUserCheck as proFalFaUserCheck,
  faUsers as proFalFaUsers,
  faUserTag as proFalFaUserTag,
  faVideoPlus as proFalFaVideoPlus,
  faWallet as proFalFaWallet,
  faWaveSine as proFalFaWaveSine,
  faWindowMaximize as proFalFaWindowMaximize
} from '@fortawesome/pro-light-svg-icons'

import {
  faAngleDown as proFasFaAngleDown,
  faAngleUp as proFasFaAngleUp,
  faAnalytics as proFasFaAnalytics,
  faAsterisk as proFasFaAsterisk,
  faArrowToTop as proFasFaArrowToTop,
  faArrowAltToTop as proFasFaArrowAltToTop,
  faArrowAltDown as proFasFaArrowAltDown,
  faArrowAltRight as proFasFaArrowAltRight,
  faArrowAltToBottom as proFasFaArrowAltToBottom,
  faArrowAltUp as proFasFaArrowAltUp,
  faArrowRight as proFasFaArrowRight,
  faArrowsH as proFasFaArrowsH,
  faArrows as proFasFaArrows,
  faArrowCircleLeft as proFasFaArrowCircleLeft,
  faArrowCircleRight as proFasFaArrowCircleRight,
  faBars as proFasFaBars,
  faBell as proFasFaBell,
  faBells as proFasFaBells,
  faBellOn as proFasFaBellOn,
  faBolt as proFasFaBolt,
  faCalendarAlt as proFasFaCalendarAlt,
  faCamera as proFasFaCamera,
  faCaretDown as proFasFaCaretDown,
  faCaretUp as proFasFaCaretUp,
  faChartBar as proFasFaChartBar,
  faChartLine as proFasFaChartLine,
  faChartNetwork as proFasFaChartNetwork,
  faCheck as proFasFaCheck,
  faCheckCircle as proFasFaCheckCircle,
  faChevronDoubleLeft as proFasFaChevronDoubleLeft,
  faChevronDoubleRight as proFasFaChevronDoubleRight,
  faChevronLeft as proFasFaChevronLeft,
  faChevronRight as proFasFaChevronRight,
  faCircle as proFasFaCircle,
  faCog as proFasFaCog,
  faCommentsDollar as proFasFaCommentsDollar,
  faCompress as proFasFaCompress,
  faCrown as proFasFaCrown,
  faCubes as proFasFaCubes,
  faEdit as proFasFaEdit,
  faExchange as proFasFaExchange,
  faEllipsisV as proFasFaEllipsisV,
  faEllipsisH as proFasFaEllipsisH,
  faExternalLink as proFasFaExternalLink,
  faExpand as proFasFaExpand,
  faFileAlt as proFasFaFileAlt,
  faFileExport as proFasFaFileExport,
  faFilePdf as proFasFaFilePdf,
  faFileInvoiceDollar as proFasFaFileInvoiceDollar,
  faFilter as proFasFaFilter,
  faFrown as proFasFaFrown,
  faLaptopCode as proFasFaLaptopCode,
  faLaugh as proFasFaLaugh,
  faLink as proFasFaLink,
  faLock as proFasFaLock,
  faLockAlt as proFasFaLockAlt,
  faGlobe as proFasFaGlobe,
  faHeart as proFasFaHeart,
  faMailBulk as proFasFaMailBulk,
  faMapMarkerCheck as proFasFaMapMarkerCheck,
  faMapMarkerSlash as proFasFaMapMarkerSlash,
  faMapMarkerAlt as proFasFaMapMarkerAlt,
  faMapMarkerTimes as proFasFaMapMarkerTimes,
  faMapPin as proFasFaMapPin,
  faMeh as proFasFaMeh,
  faMinusCircle as proFasFaMinusCircle,
  faPaperPlane as proFasFaPaperPlane,
  faPlay as proFasFaPlay,
  faPlayCircle as proFasFaPlayCircle,
  faPlus as proFasFaPlus,
  faQuestionCircle as proFasFaQuestionCircle,
  faMinus as proFasFaMinus,
  faPlusCircle as proFasFaPlusCircle,
  faRadar as proFasFaRadar,
  faShareAlt as proFasFaShareAlt,
  faShare as proFasFaShare,
  faShareSquare as proFasFaShareSquare,
  faSignalAlt1 as proFasFaSignalAlt1,
  faSignalAlt3 as proFasFaSignalAlt3,
  faSignalStream as proFasFaSignalStream,
  faSignOutAlt as proFasFaSignOutAlt,
  faSlash as proFasFaSlash,
  faSpinner as proFasFaSpinner,
  faStar as proFasFaStar,
  faStream as proFasFaStream,
  faSyncAlt as proFasFaSyncAlt,
  faThumbsUp as proFasFaThumbsUp,
  faThumbsDown as proFasFaThumbsDown,
  faThumbtack as proFasFaThumbtack,
  faTriangle as proFasFaTriangle,
  faTimes as proFasFaTimes,
  faTrashAlt as proFasFaTrashAlt,
  faTimesCircle as proFasFaTimesCircle,
  faToggleOn as proFasFaToggleOn,
  faTrophy as proFasFaTrophy,
  faUniversity as proFasFaUniversity,
  faUser as proFasFaUser,
  faUsers as proFasFaUsers,
  faUserChart as proFasFaUserChart,
  faUserCircle as proFasFaUserCircle,
  faVideoPlus as proFasFaVideoPlus,
  faVideoSlash as proFasFaVideoSlash,
  faWallet as proFasFaWallet,
  faWaveSine as proFasFaWaveSine,
  faWindowMaximize as proFasFaWindowMaximize,
  faWindowRestore as proFasFaWindowRestore
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  freeFabFaGoogle,
  freeFabFaFacebookF,
  freeFabFaWhatsapp,
  freeFabFaBitcoin,
  freeFabFaYoutube,
  freeFabFaInstagram,
  freeFabFaApple,
  freeFabFaGooglePlay,
  proFalFaAngleDown,
  proFalFaAngleUp,
  proFalFaAnalytics,
  proFalFaArrowAltDown,
  proFalFaArrowAltUp,
  proFalFaLongArrowLeft,
  proFalFaArrowToRight,
  proFalFaArrowToBottom,
  proFalFaArrowToTop,
  proFalFaBalanceScaleLeft,
  proFalFaBadgePercent,
  proFalFaBallotCheck,
  proFalFaBell,
  proFalFaBellExclamation,
  proFalFaBellOn,
  proFalFaBellPlus,
  proFalFaBellSlash,
  proFalFaCalendar,
  proFalFaCalendarAlt,
  proFalFaCamera,
  proFalFaChartBar,
  proFalFaChartLine,
  proFalFaChartLineDown,
  proFalFaChartPie,
  proFalFaChartNetwork,
  proFalFaChartPieAlt,
  proFalFaChartScatter,
  proFalFaCheckCircle,
  proFalFaCircle,
  proFalFaClock,
  proFalFaClone,
  proFalFaCog,
  proFalFaCommentAltSlash,
  proFalFaCommentAltDollar,
  proFalFaCommentCheck,
  proFalFaCommentPlus,
  proFalFaCreditCardFront,
  proFalFaCreditCard,
  proFalFaDesktop,
  proFalFaDiploma,
  proFalFaDonate,
  proFalFaEnvelope,
  proFalFaEnvelopeOpenDollar,
  proFalFaExchange,
  proFalFaExclamationCircle,
  proFalFaExclamationTriangle,
  proFalFaFile,
  proFalFaFileAlt,
  proFalFaFileChartPie,
  proFalFaFileDownload,
  proFalFaFileImage,
  proFalFaFileInvoiceDollar,
  proFalFaFilePdf,
  proFalFaFilter,
  proFalFaFlag,
  proFalFaFrown,
  proFalFaGlobe,
  proFalFaHistory,
  proFalFaHourglassStart,
  proFalFaHourglassEnd,
  proFalFaLaptopCode,
  proFalFaLayerMinus,
  proFalFaLayerPlus,
  proFalFaLevelDown,
  proFalFaLevelUp,
  proFalFaLightbulbSlash,
  proFalFaLightbulbOn,
  proFalFaLightbulbDollar,
  proFalFaLockAlt,
  proFalFaHandHoldingUsd,
  proFalFaHashtag,
  proFalFaHeart,
  proFalFaIdBadge,
  proFalFaLaugh,
  proFalFaMailBulk,
  proFalFaMapMarkerCheck,
  proFalFaMapMarkerSlash,
  proFalFaMapMarkerAlt,
  proFalFaMeh,
  proFalFaMobileAndroid,
  proFalFaNewspaper,
  proFalFaPaperclip,
  proFalFaPaperPlane,
  proFalFaPlay,
  proFalFaPlayCircle,
  proFalFaPlusCircle,
  proFalFaQrcode,
  proFalFaQuestionCircle,
  proFalFaRadar,
  proFalFaRandom,
  proFalFaSearch,
  proFalFaSearchDollar,
  proFalFaShareAll,
  proFalFaScroll,
  proFalFaSignalStream,
  proFalFaSignIn,
  proFalFaSignOut,
  proFalFaStream,
  proFalFaStar,
  proFalFaTag,
  proFalFaTachometerFast,
  proFalFaTachometerAltFastest,
  proFalFaTachometerAltSlow,
  proFalFaThumbsUp,
  proFalFaThumbsDown,
  proFalFaThumbtack,
  proFalFaTimes,
  proFalFaTicket,
  proFalFaTimesCircle,
  proFalFaToggleOff,
  proFalFaToggleOn,
  proFalFaUniversity,
  proFalFaUnlockAlt,
  proFalFaUser,
  proFalFaUserChart,
  proFalFaUserCircle,
  proFalFaUserCog,
  proFalFaUserCrown,
  proFalFaUsersCrown,
  proFalFaUserCheck,
  proFalFaUsers,
  proFalFaUserTag,
  proFalFaVideoPlus,
  proFalFaWallet,
  proFalFaWaveSine,
  proFalFaWindowMaximize,
  proFasFaAngleDown,
  proFasFaAngleUp,
  proFasFaAnalytics,
  proFasFaAsterisk,
  proFasFaArrowToTop,
  proFasFaArrowAltToTop,
  proFasFaArrowAltDown,
  proFasFaArrowAltRight,
  proFasFaArrowAltToBottom,
  proFasFaArrowAltUp,
  proFasFaArrowRight,
  proFasFaArrowsH,
  proFasFaArrows,
  proFasFaArrowCircleLeft,
  proFasFaArrowCircleRight,
  proFasFaBars,
  proFasFaBell,
  proFasFaBells,
  proFasFaBellOn,
  proFasFaBolt,
  proFasFaCalendarAlt,
  proFasFaCamera,
  proFasFaCaretDown,
  proFasFaCaretUp,
  proFasFaChartBar,
  proFasFaChartLine,
  proFasFaChartNetwork,
  proFasFaCheck,
  proFasFaCheckCircle,
  proFasFaChevronDoubleLeft,
  proFasFaChevronDoubleRight,
  proFasFaChevronLeft,
  proFasFaChevronRight,
  proFasFaCircle,
  proFasFaCog,
  proFasFaCommentsDollar,
  proFasFaCompress,
  proFasFaCrown,
  proFasFaCubes,
  proFasFaEdit,
  proFasFaExchange,
  proFasFaEllipsisV,
  proFasFaEllipsisH,
  proFasFaExternalLink,
  proFasFaExpand,
  proFasFaFileAlt,
  proFasFaFileExport,
  proFasFaFilePdf,
  proFasFaFileInvoiceDollar,
  proFasFaFilter,
  proFasFaFrown,
  proFasFaLaptopCode,
  proFasFaLaugh,
  proFasFaLink,
  proFasFaLock,
  proFasFaLockAlt,
  proFasFaGlobe,
  proFasFaHeart,
  proFasFaMailBulk,
  proFasFaMapMarkerCheck,
  proFasFaMapMarkerSlash,
  proFasFaMapMarkerAlt,
  proFasFaMapMarkerTimes,
  proFasFaMapPin,
  proFasFaMeh,
  proFasFaMinusCircle,
  proFasFaPaperPlane,
  proFasFaPlay,
  proFasFaPlayCircle,
  proFasFaPlus,
  proFasFaQuestionCircle,
  proFasFaMinus,
  proFasFaPlusCircle,
  proFasFaRadar,
  proFasFaShareAlt,
  proFasFaShare,
  proFasFaShareSquare,
  proFasFaSignalAlt1,
  proFasFaSignalAlt3,
  proFasFaSignalStream,
  proFasFaSignOutAlt,
  proFasFaSlash,
  proFasFaSpinner,
  proFasFaStar,
  proFasFaStream,
  proFasFaSyncAlt,
  proFasFaThumbsUp,
  proFasFaThumbsDown,
  proFasFaThumbtack,
  proFasFaTriangle,
  proFasFaTimes,
  proFasFaTrashAlt,
  proFasFaTimesCircle,
  proFasFaToggleOn,
  proFasFaTrophy,
  proFasFaUniversity,
  proFasFaUser,
  proFasFaUsers,
  proFasFaUserChart,
  proFasFaUserCircle,
  proFasFaVideoPlus,
  proFasFaVideoSlash,
  proFasFaWallet,
  proFasFaWaveSine,
  proFasFaWindowMaximize,
  proFasFaWindowRestore
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
